<template>
  <v-card class="key-card" width="100%">
    <v-card-title>Got a Tree Key?</v-card-title>
    <v-card-text>
      <v-text-field
        hint="Enter your Tree Key and click apply"
        label="Enter your Tree Key"
        rounded
        filled
        :loading="isLoading"
        :disabled="isLoading"
        v-model="coupon"
      >
        <template v-slot:append>
          <v-layout class="align-apply-center">
            <v-btn
              v-if="!isLoading"
              text
              color="primary"
              rounded
              @click="applyCoupon"
              :disabled="!coupon"
              >Apply</v-btn
            >
          </v-layout>
        </template>
      </v-text-field>
      <div>
        <v-icon color="blue darken-3">mdi-information</v-icon>
        You may have recieved a Tree Key from one of our partners, or from your
        employer, and it can be exchanged for more free trees!
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { ApiService } from "@/services/ApiService";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      coupon: null,
    };
  },
  computed: {
    ...mapGetters(["getCurrentUser"]),
  },
  methods: {
    ...mapActions(["fetchUserFSDocument"]),
    ...mapMutations(["setErrorSnackbarMessage","showFeedbackDialogData"]),

    async applyCoupon() {
      try {
        this.isLoading = true;
        const response = await ApiService.post("/coupons/consume", {
          uid: this.getCurrentUser.uid,
          couponCode: this.coupon,
        });
        const message = response.data;
        this.showFeedbackDialogData({
          title: "Success",
          description: message,
        });
        // re-fetch user document
        await this.fetchUserFSDocument();
      } catch (error) {
        this.setErrorSnackbarMessage(
          error.response.data.message || "coupon invalid"
        );
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style  scoped>
.key-card {
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(91, 91, 91, 0.16) !important;
  border-radius: 16px;
}
.align-apply-center {
  margin-top: -6px !important;
}
</style>