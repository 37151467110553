<template>
  <div class="my-12">
    <div class="title-text">Quick actions</div>
    <v-btn
      block
      rounded
      outlined
      class="my-4"
      large
      color="primary"
      @click="$router.push({ name: 'Calculator' })"
    >
      <span class="btn-text"> Re-calculate your carbon </span>
    </v-btn>
    <v-btn
      @click="openCustomerPortal"
      block
      rounded
      outlined
      class="my-4"
      large
      color="primary"
      :loading="createPortalSessionLoading"
      :disabled="createPortalSessionLoading"
    >
      <span class="btn-text">Billing & Payment</span>
    </v-btn>
    <v-btn
      block
      rounded
      outlined
      class="my-4"
      large
      color="primary"
      @click="$router.push({ name: 'Onboarding' })"
      ><span class="btn-text"> How it works </span>
    </v-btn>
  </div>
</template>

<script>
import { ApiService } from "@/services/ApiService";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      sessionUrl: null,
      createPortalSessionLoading: false,
    };
  },
  computed: {
    ...mapGetters(["getCurrentUser"]),
  },
  methods: {
    async createPortalSession() {
      this.createPortalSessionLoading = true;
      try {
        const response = await ApiService.post("/payment/createPortalSession", {
          uid: this.getCurrentUser.uid,
        });
        this.sessionUrl = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.createPortalSessionLoading = false;
      }
    },
    async openCustomerPortal() {
      await this.createPortalSession();
      window.open(this.sessionUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}
.title-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #333333;
}
</style>