<template>
  <v-row class="px-0">
    <v-col class="px-0">
      <v-row class="pb-3" no-gutters>
        <div class="forest-description">Trees planted in your honour</div>
      </v-row>
      <v-row class="pb-3" no-gutters>
        <v-chip color="#F2F2F2" class="status-chip-text">
          Status: {{ forestStatus }}
        </v-chip>
      </v-row>
    </v-col>
    <v-col cols="auto" class="trees-number">{{
      getUserFSDocument.treesAccumulated
    }}</v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["getUserFSDocument"]),

    getUserStartDate() {
      return this.getUserFSDocument?.subscription?.startDate?.toDate();
    },

    // todo : check number of trees with matt & andreas
    forestStatus() {
      if (!this.getUserFSDocument?.treesAccumulated) {
        return "";
      }
      if (this.getUserFSDocument?.treesAccumulated < 30) {
        return "Weeds In the garden";
      }
      if (this.getUserFSDocument?.treesAccumulated < 60) {
        return "Gaining ground";
      }
      if (this.getUserFSDocument?.treesAccumulated < 120) {
        return "Deamy suburia";
      }
      if (this.getUserFSDocument?.treesAccumulated < 180) {
        return "Busy pruning";
      }
      return "Welcome to the jungle";
    },
  },
};
</script>

<style  scoped>
.trees-number {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  color: #333333;
}
.status-chip-text {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 11px;
  color: #333333;
}
.forest-description {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}
</style>