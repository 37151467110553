<template>
  <v-dialog
    :value="getIsYourForestDialogOpen"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card class="spartan-font">
      <!-- toolbar -->
      <v-row justify="start" class="col-12 pt-10">
        <v-btn @click="setIsYourForestDialogOpen(false)" fab text>
          <v-icon color="black"> mdi-arrow-left </v-icon>
        </v-btn>
      </v-row>
      <!-- header image -->
      <div class="page-image-header">
        <v-img
          :src="require('@/assets/your-forest/forest-cover-small.svg')"
        ></v-img>
        <div class="page-title">Your Forest</div>
      </div>
      <div>
        <div class="mx-10">
          <v-row class="my-10">
            <ForestNameInput />
          </v-row>
          <v-row class="mt-10">
            <TreesStatusAndNumber />
          </v-row>
          <v-row class="mt-8 page-description">
            As part of your Zero plan, we plant trees for you each month. Before
            they grow and can offset your carbon, they’ll provide jobs, homes
            for wildlife, and let’s face it, happiness.
          </v-row>
          <v-row class="mt-10">
            <TreesCouponCard />
          </v-row>
          <v-row class="mt-10 pb-14">
            <v-img
              rounded
              :src="require('@/assets/your-forest/forest-footer.png')"
            ></v-img>
          </v-row>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ForestNameInput from "@/components/customer-dashboard/Forest/ForestNameInput.vue";
import TreesStatusAndNumber from "@/components/customer-dashboard/Forest/TreesStatusAndNumber.vue";
import TreesCouponCard from "@/components/customer-dashboard/Forest/YourForestDialog/TreesCouponCard.vue";

export default {
  components: {
    ForestNameInput,
    TreesStatusAndNumber,
    TreesCouponCard,
  },

  computed: {
    ...mapGetters(["getIsYourForestDialogOpen"]),
  },
  methods: {
    ...mapMutations(["setIsYourForestDialogOpen"]),
  },
};
</script>

<style scoped>
.page-image-header {
  position: relative;
}
.page-title {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 140%;
}
.page-description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;

  color: #333333;
}
</style>