<template>
  <div>
    <v-card class="card my-5">
      <v-card-text class="pa-10">
        <v-row class="card-title pb-2"> In the field </v-row>
        <v-row class="pb-5 card-description">
          News about the projects you’re supporting
        </v-row>
        <div class="mb-4" v-for="project in projects" :key="project.title">
          <InTheFieldProjectItem
            :title="project.title"
            :category="project.category"
            :updatesNumber="project.updatesNumber"
            :image="project.image"
            :link="project.link"
          />
        </div>
        <a href="https://www.zero.thetoucan.app/projects" target="_blank">
          <v-row>
            <v-btn block class="cta-button px-0" dark rounded large depressed>
              <v-icon small class="mr-3">mdi-view-dashboard-outline</v-icon>
              See All
            </v-btn>
          </v-row>
        </a>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import InTheFieldProjectItem from "@/components/customer-dashboard/InTheField/InTheFieldProjectItem.vue";
export default {
  components: { InTheFieldProjectItem },
  data() {
    return {
      projects: [
        {
          title: "Gujarat solar",
          category: "Solar Farm",
          updatesNumber: 2,
          image: require("@/assets/projects/solar-farm.png"),
          link: "https://www.zero.thetoucan.app/projects/gujarat-solar",
        },
        {
          title: "Keo Seima",
          category: "Forest Conservation",
          updatesNumber: 1,
          image: require("@/assets/projects/forest-conservation.png"),

          link: "https://www.zero.thetoucan.app/projects/keo-seima",
        },
      ],
    };
  },
};
</script>

<style  scoped>
.card {
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(91, 91, 91, 0.16) !important;
  border-radius: 16px !important;
}
.card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #bdbdbd;
}
.card-description {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
  color: #4f4f4f;
}
</style>