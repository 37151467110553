<template>
  <div>
    <v-card class="card my-5">
      <v-card-text class="pa-5">
        <v-row class="card-title py-0" no-gutters align="center">
          Your badge of goodness
        </v-row>
        <v-row align="center">
          <v-col cols="auto">
            <!-- todo : change with 50% badge -->
            <v-img
              v-if="planPercentage == 50"
              :src="require('@/assets/plan-logo.svg')"
            >
            </v-img>
            <v-img
              v-if="planPercentage == 100"
              :src="require('@/assets/plan-logo.svg')"
            >
            </v-img>
            <!-- todo : change with 150% badge -->
            <v-img
              v-if="planPercentage == 150"
              :src="require('@/assets/plan-logo.svg')"
            >
            </v-img>
          </v-col>
          <v-col>
            <v-row>
              <div class="pb-2 text-description">
                We think you’re top notch. And so will your friends.
              </div>
            </v-row>
            <v-row>
              <v-btn
                @click="openSocialShareDialog"
                block
                class="cta-button"
                dark
                rounded
                large
                depressed
              >
                <v-row align="center" justify="start">
                  <img
                    :src="require('@/assets/icons/share.svg')"
                    alt="plan logo"
                  />
                  Share your badge
                  <SharingDialog ref="shareDialog" />
                </v-row>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import SharingDialog from "@/components/customer-dashboard/SharingDialog/SharingDialog.vue";

export default {
  props: {
    planPercentage: {
      type: Number,
      default: 100,
    },
  },
  components: {
    SharingDialog,
  },
  methods: {
    openSocialShareDialog() {
      this.$refs.shareDialog.openDialog();
    },
  },
};
</script>

<style  scoped>
.card {
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(91, 91, 91, 0.16) !important;
  border-radius: 16px !important;
}
.card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #bdbdbd;
}
.text-description {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  color: #333333;
}
.cta-button img {
  width: 25px;
  height: 25px;
  margin: 10px;
}
</style>