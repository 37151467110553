<template>
  <div>
    <YourForestDialog />
    <v-card class="card my-5">
      <v-card-text class="pa-10">
        <v-row class="card-title pb-5"> Your forest of friendliness </v-row>
        <ForestNameInput />
        <TreesStatusAndNumber />

        <v-row>
          <v-btn
            @click="setIsYourForestDialogOpen(true)"
            block
            class="cta-button px-0"
            dark
            rounded
            large
            depressed
          >
            <v-icon small class="mr-2">mdi-information-outline</v-icon>
            About your forest
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import YourForestDialog from "@/components/customer-dashboard/Forest/YourForestDialog/YourForestDialog.vue";
import ForestNameInput from "@/components/customer-dashboard/Forest/ForestNameInput.vue";
import TreesStatusAndNumber from "@/components/customer-dashboard/Forest/TreesStatusAndNumber.vue";
export default {
  components: {
    YourForestDialog,
    ForestNameInput,
    TreesStatusAndNumber,
  },

  methods: {
    ...mapMutations(["setIsYourForestDialogOpen"]),
  },
};
</script>

<style  scoped>
.card {
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(91, 91, 91, 0.16) !important;
  border-radius: 16px !important;
}
.card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #bdbdbd;
}
</style>