<template>
  <div>
    <v-card class="card my-5">
      <v-card-text class="pa-10">
        <v-row class="card-title pb-5"> Carbon-be-gone </v-row>
        <v-row>
          <div class="pb-2 card-heading">
            You’ve offset {{ carbonOffseted.toFixed(2) }} tCO₂ so far
          </div>
        </v-row>
        <v-row>
          <div class="text-description">
            ️That’s the same as {{ flightsEquivalentToOffseting }} flight from
            London to New York 🛩
          </div>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    carbonOffseted: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    flightsEquivalentToOffseting() {
      // each fligh cost .7 tCO₂
      const oneFlightEmissions = 0.7;
      return Math.round(this.carbonOffseted / oneFlightEmissions);
    },
  },
};
</script>

<style  scoped>
.card {
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(91, 91, 91, 0.16) !important;
  border-radius: 16px !important;
}
.card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #bdbdbd;
}
.card-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
}
.text-description {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  color: #333333;
}
.cta-button img {
  width: 25px;
  height: 25px;
  margin: 10px;
}
</style>