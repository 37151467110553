<template>
  <v-row align="center" justify="start">
    <div>
      <v-img width="35px" height="56px" contain :src="image" />
    </div>

    <v-col>
      <div class="primary-title mb-2">{{ title }}</div>
      <div>
        <v-chip class="text-truncate" small color="#F2F2F2">
          {{ category }}
        </v-chip>
      </div>
    </v-col>
    <v-col cols="auto" class="pa-0">
      <div class="project-link">
        <a :href="link" class="black--text" target="_blank">
          {{ updatesNumber }} update(s)
          <v-icon color="black" small>mdi-arrow-right</v-icon></a
        >
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    category: {
      type: String,
      default: "",
    },
    updatesNumber: {
      type: Number,
      default: 1,
    },
    link: {
      type: String,
      default: "#",
    },
    image: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.primary-title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}
.category-chip {
  max-width: 88px !important;
  text-overflow: ellipsis;
}
.project-link {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
}
</style>