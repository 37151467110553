<template>
  <div>
    <v-dialog v-model="isDialogOpen" width="500">
      <v-card rounded="lg">
        <v-card-title class="text-h5 primary white--text elevation-5">
          Share on Social Media
        </v-card-title>

        <v-card-text class="pa-10 text-center">
          <span v-for="network in networks" :key="network.name">
            <ShareNetwork
              :network="network.name"
              :url="myBadgeUrl"
              title="Anas KASMI just earned a Zero Hero Badge"
              description="Offset your carbon as well and sleep guilt free"
              quote="Offset your carbon as well and sleep guilt free"
            >
              <v-btn fab :color="network.color" class="mx-2 my-1" dark>
                <v-icon>mdi-{{ network.network }}</v-icon>
              </v-btn>
            </ShareNetwork>
          </span>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ShareNetwork } from "vue-social-sharing";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isDialogOpen: false,
      networks: [
        {
          network: "email",
          name: "Email",
          icon: "envelope",
          color: "#333333",
        },

        {
          network: "facebook",
          name: "Facebook",
          icon: "fab fah fa-lg fa-facebook-f",
          color: "#1877f2",
        },

        {
          network: "reddit",
          name: "Reddit",
          icon: "fab fah fa-lg fa-reddit-alien",
          color: "#ff4500",
        },

        {
          network: "twitter",
          name: "Twitter",
          icon: "fab fah fa-lg fa-twitter",
          color: "#1da1f2",
        },

        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "fab fah fa-lg fa-whatsapp",
          color: "#25d366",
        },
      ],
    };
  },
  components: {
    ShareNetwork,
  },
  methods: {
    openDialog() {
      this.isDialogOpen = true;
    },
    closeDialog() {
      this.isDialogOpen = false;
    },
  },
  computed: {
    ...mapGetters(["getCurrentUser"]),
    myBadgeUrl() {
      return (
        process.env.VUE_APP_FRONT_BASE_URL +
        "/badgePreview/" +
        this.getCurrentUser.uid
      );
    },
  },
};
</script>

<style  scoped>
</style>