<template>
  <div>
    <v-card class="card my-5">
      <v-card-text class="pa-10">
        <v-row class="card-title pb-2"> Being green and mean </v-row>
        <v-row class="pb-5 card-description">
          How to keep up the good work
        </v-row>
        <!-- loader -->
        <div v-if="isLoading">
          <v-skeleton-loader
            v-for="i in 3"
            :key="i"
            type="table-heading"
          ></v-skeleton-loader>
        </div>
        <div v-else class="mb-4" v-for="media in getMedia" :key="media.title">
          <BeingGreenVideoItem
            :title="media.title"
            :thumbnail="media.thumbnailUrl"
            :link="media.mediaUrl"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import BeingGreenVideoItem from "@/components/customer-dashboard/BeingGreen/BeingGreenVideoItem.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: { BeingGreenVideoItem },
  created() {
    this.startFetchingMedia();
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["getMedia"]),
  },
  methods: {
    ...mapActions(["fetchMedia"]),
    ...mapMutations(["setErrorSnackbarMessage"]),
    async startFetchingMedia() {
      this.isLoading = true;
      try {
        await this.fetchMedia();
      } catch (error) {
        this.setErrorSnackbarMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style  scoped>
.card {
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(91, 91, 91, 0.16) !important;
  border-radius: 16px !important;
}
.card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #bdbdbd;
}
.card-description {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
  color: #4f4f4f;
}
</style>