<template>
  <v-row>
    <!-- label -->
    <div
      v-if="!showNameInput"
      @click="showNameInput = true"
      class="pb-2 name-forest-input"
    >
      {{ forestName || "Name your forest" }}
      <v-icon color="#dcdcdc"> mdi-lead-pencil </v-icon>
    </div>
    <!-- name input if label clicked or if forest alreay has a name -->
    <v-text-field
      v-if="showNameInput"
      class="name-forest-input"
      hint="Name your forest"
      solo-inverted
      :append-icon="'mdi-check-bold'"
      @click:append="changeForestName()"
      v-model="forestName"
    >
      Name your forest
    </v-text-field>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  created() {
    this.forestName = this.getUserFSDocument.forestName;
  },
  computed: {
    ...mapGetters(["getUserFSDocument"]),
  },
  data() {
    return {
      showNameInput: false,
      forestName: null,
      isLoading: false,
    };
  },
  methods: {
    ...mapActions(["updateUserDocument", "fetchUserFSDocument"]),
    async changeForestName() {
      this.isLoading = true;
      try {
        await this.updateUserDocument({
          forestName: this.forestName,
        });
        this.showNameInput = false;
      } catch (error) {
        console.log(error);
        this.$alertsService.fireGeneralError(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.name-forest-input {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  color: rgba(51, 51, 51, 0.2);
}
</style>