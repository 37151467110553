<template>
  <a :href="link" target="_blank">
    <v-row align="center" justify="space-between">
      <div>
        <v-img
          class="project-thumbnail"
          width="35px"
          height="56px"
          :src="thumbnail"
        />
      </div>

      <div class="project-link">
        <div class="black--text primary-title">
          {{ title }}
        </div>
      </div>
      <div>
        <v-icon color="black" small>mdi-arrow-right</v-icon>
      </div>
    </v-row>
  </a>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "#",
    },
    thumbnail: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.primary-title {
  font-style: normal;
  white-space: initial;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

.project-link {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  width: 70%;
  line-height: 16px;
  color: #333333;
}
.project-thumbnail {
  border-radius: 5px !important;
  margin: 10px;
}
</style>