<template>
  <div>
    <!-- email verification banner -->
    <!-- only show to users signed up using email, not phone number  -->
    <v-alert
      v-if="getCurrentUser && !getCurrentUser.emailVerified && userHasEmail"
      elevation="0"
      rounded="sm"
      type="error"
      color="orange"
      class="col-12 mb-8"
      prominent
    >
      <v-row justify="space-between" no-gutters align="center">
        <div class="font-weight-bold">Email Unverified</div>
        <v-btn
          v-if="!isEmailResent"
          dark
          text
          @click="resendEmailVerification"
          :disabled="isEmailResent"
          >resend</v-btn
        >
        <v-btn v-if="isEmailResent" dark text disabled>sent</v-btn>
      </v-row>
    </v-alert>
    <div v-if="isLoading" class="my-14">
      <v-row justify="center" align="center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
    </div>
    <div v-if="!isLoading && subscription">
      <div class="header mb-10">Welcome to Zero, {{ firstName }}</div>
      <PlanCard
        :product="subscription"
        :showCTAButton="false"
        :showChangePlan="true"
      />
      <BadgeOfGodnessCard :planPercentage="parseInt(subscription.percentage)" />
      <CarbonBeGoneCard :carbonOffseted="getUserFSDocument.carbonOffseted" />
      <YourForestCard :userDocument="getUserFSDocument" />
      <InTheFieldCard />
      <BeingGreenCard />
      <QuickActions />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import PlanCard from "@/components/choose-plan/PlanCard.vue";
import BadgeOfGodnessCard from "@/components/customer-dashboard/BadgeOfGodnessCard.vue";
import CarbonBeGoneCard from "@/components/customer-dashboard/CarbonBeGoneCard.vue";
import YourForestCard from "@/components/customer-dashboard/Forest/YourForestCard.vue";
import QuickActions from "@/components/customer-dashboard/QuickActions.vue";
import InTheFieldCard from "@/components/customer-dashboard/InTheField/InTheFieldCard.vue";
import BeingGreenCard from "@/components/customer-dashboard/BeingGreen/BeingGreenCard.vue";

export default {
  async created() {
    // set the app bar title
    this.setCurrentAppBarTitle("Good morning");

    await this.beforeEnterMiddleware();
  },
  components: {
    PlanCard,
    BadgeOfGodnessCard,
    CarbonBeGoneCard,
    YourForestCard,
    QuickActions,
    InTheFieldCard,
    BeingGreenCard,
  },
  data() {
    return {
      isLoading: false,
      subscription: null,
      firstName: null,
      isEmailResent: false,
    };
  },
  computed: {
    ...mapGetters(["getCurrentUser", "getUserFSDocument"]),
    // this helps find out if user was logged in using his email or phone number,
    userHasEmail() {
      return !!this.getCurrentUser.email;
    },
  },
  methods: {
    ...mapActions(["fetchUserFSDocument", "sendEmailVerification"]),
    ...mapMutations([
      "setErrorSnackbarMessage",
      "setCurrentAppBarTitle",
      "showFeedbackDialogData",
    ]),

    async beforeEnterMiddleware() {
      try {
        this.isLoading = true;
        await this.fetchUserFSDocument();
        this.firstName = this.getUserFSDocument?.firstName;
        this.subscription = this.getUserFSDocument?.subscription;
        if (!this.subscription || this.subscription?.status != "paid") {
          // redirect to choose plan, if user has no plan or plan is not paid
          this.$router.push({
            name: "ChoosePlan",
          });
        }
      } catch (error) {
        console.log(error);
        this.setErrorSnackbarMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
    resendEmailVerification() {
      // mark email is resent to hide the resend button
      this.isEmailResent = true;
      this.sendEmailVerification(this.getCurrentUser);
      this.showFeedbackDialogData({
        title: "Verification email resent",
        description: "Please check you SPAM forlder as well",
      });
    },
  },
};
</script>
<style scoped>
.header {
  font-style: normal;
  font-weight: 900;
  font-size: 34px;
  line-height: 45px;
  color: #00b08d;
}
</style>
